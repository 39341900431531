import { render, staticRenderFns } from "./FunnelChartCarousel.vue?vue&type=template&id=aabed25a&"
import script from "./FunnelChartCarousel.vue?vue&type=script&lang=js&"
export * from "./FunnelChartCarousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCarousel,VCarouselItem,VRow,VSheet,VSubheader})
