import {get} from 'object-path'

export default {
    data: () => ({
        sortStrategy: {
            collected(a, b, isDesc) { // IT IS NOT ARROW BECAUSE OF THIS
                const first = Object.entries(a.amount || {}).reduce((acc, [,amount]) => acc + amount, 0)
                const second = Object.entries(b.amount || {}).reduce((acc, [,amount]) => acc + amount, 0)
                return this.$sortHelper(first, second, isDesc)
            },
            balances(a, b, isDesc, key) {
                const first = get(a, key).reduce((acc, {amount}) => acc + amount, 0)
                const second = get(b, key).reduce((acc, {amount}) => acc + amount, 0)

                return this.$sortHelper(first, second, isDesc)
            },
            default(a, b, isDesc, key){
                return this.$sortHelper(get(a, key), get(b, key), isDesc)
            }
        }
    }),
    methods: {
        customSort(items, [key], [isDesc]) {
            items.sort((a, b) => {
                const ref = this.sortStrategy
                return ref[ref[key] ? key : 'default'].call(this, a, b, isDesc, key)
            });

            return items;
        },
    }
}
