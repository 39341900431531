<template>
  <v-carousel
      v-model="carouselController"
      :hide-delimiters="true"
      :show-arrows="false"
  > <!-- I AM USING CAROUSEL FOR FUTURE PURPOSES - OTHER OPERATORS -->
    <v-carousel-item v-for="({operator, ...item}, index) in carouselItems" :key="index">
      <v-sheet
          height="100%"
          tile
      >
        <v-subheader>{{ operator }}</v-subheader>
        <v-row
            class="fill-height"
            align="center"
            justify="center"
        >

          <div class="text-h2">
            <vue-funnel-graph
                :height="item.height"
                :width="item.width"
                :key="index"
                :labels="item.labels"
                :display-percent="item['display-percent']"
                :display-percentage="item['display-percentage']"
                :animated="item.animated"
                :colors="item.colors"
                :values="item.values"
            ></vue-funnel-graph>
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import {VueFunnelGraph} from "vue-funnel-graph-js";

export default {
  name: "FunnelChartCard",
  components: {
    VueFunnelGraph
  },
  data: () => ({
    carouselController: 0
  }),
  props: {
    carouselItems: {type: Array, default: () => []}
  }
}
</script>
