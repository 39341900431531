import {
    transactionsHoursChartHeaders,
    hoursObject
} from '@/utils/macros/variables'

export default {
    data: () => ({
        transactionalHoursChartData: [transactionsHoursChartHeaders], // PREFER IN DATA NOT IN MIXIN,
        hoursObject
    }),
    methods: {
        prepareBlueMediaDonationProcessChart(data) {
            this.funnelChart.values = [...data || []].sort(([a], [b]) => a > b ? 1 : -1).map(([, amount]) => amount)
        },
        setTransactionalHoursChartData(data) {
            this.transactionalHoursChartData =
                [transactionsHoursChartHeaders, ...Object.entries(data)
                    .sort((a, b) => ~~a[0] < ~~b[0] ? -1 : 1) // SORTING HOURS FROM 00:00 to 23:00
                    .map(([hour, amount]) => [hour + ':00', amount])] // SETTING FORMAT TO
        }
    }
}
