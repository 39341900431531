<template>
  <v-card>
    <slot></slot>
    <v-card-subtitle v-if="date">
      {{ measuredTitle }} {{ date }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "ImplementedDateWrapper",
  data: () => ({
    measuredTitle: 'Data measurement since:'
  }),
  props: {
    date: {type:String, default: ''}
  }
}
</script>
