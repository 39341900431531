<template>
  <v-tooltip
      top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon color="grey lighten-1" large>
          mdi-information-outline
        </v-icon>
      </v-btn>
    </template>
    <span v-html="message"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    message: {type: String}
  }
}
</script>
