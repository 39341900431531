<template>
  <v-card flat>
    <div v-if="tooltip" class="tooltip">
      <tooltip :message="tooltip"></tooltip>
    </div>
    <g-chart
        :type="type"
        :data="data"
        :options="options"
        @ready="onChartReady"
    />
  </v-card>
</template>

<script>
import Tooltip from "@/components/dashboard/Tooltip";

export default {
  name: "chart",
  components: {Tooltip},
  props: {
    type: {
      type: String,
    },
    data: {
      type: Array,
    },
    options: {
      type: Object,
    },
    tooltip: {
      type: String
    },
    onChartReady: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
}
</style>