import moment from "moment";
import {dateFormat, allDates} from '@/utils/macros/variables'

const settings = {
    animation:{
        startup: true,
        duration: 400,
        easing: 'inAndOut',
    },
    legend: {
        position: 'bottom',
        maxLines: 3,
        alignment: 'start',
        textStyle: {
            color: 'grey',
            fontSize: 14
        }
        },
}
export default {
    data: () => ({
        funnelChartOptions: {
            height: 350,
            width: 1000,
            'display-percent': false,
            animated: true,
            'display-percentage': true,
            breakpoints: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1000,
            },
        },
        lineChartOptions: {
            explorer: {
                    maxZoomOut: 8,
                    actions: ['dragToZoom', 'rightClickToReset']
                },
            ...settings,
            height: 800,
            hAxis: {slantedTextAngle: 90, slantedText: true},
        },
        hoursChartOptions: {
            ...settings,
            height: 500,
            hAxis: {slantedTextAngle: 45, slantedText: true},
        },
        avgPaymentTimeChartOptions: {
            ...settings,
            height: 500,
            hAxis: {slantedTextAngle: 45, slantedText: true },
            vAxis: {title: 'Time [s]'},
            colors: ['#0a2540', '#0059c2']
        },
        verticalColumnChartOptions: {
            ...settings,
            height: 500,
            hAxis: {slantedTextAngle: 45, slantedText: true},
        },
        pieChartOptions: {
            ...settings,
            breakpoints: {
                xs: 400,
                sm: 500,
                md: 700,
                lg: 1000,
                xl: 1000,
            },
            height: 550,
            pieHole: 0.2,
            tooltip: {
                text: 'both'
            },
            legend: {
                position: 'right',
                maxLines: 5,
                alignment: 'center'
            },
        },
        dataLengths: [0, 1],
        datePickers: {
            endDateMenu: false,
            startDateMenu: false,
            startDate: moment().subtract(1, 'year').format(dateFormat),
            endDate: moment().format(dateFormat),
            startDateTitle: 'Start date range',
            endDateTitle: 'End date range'
        },
        avgPaymentTimeDatePickers: {
            endDateMenu: false,
            startDateMenu: false,
            startDate: moment().subtract(1, 'year').format(dateFormat),
            endDate: moment().format(dateFormat),
            startDateTitle: 'Start date range',
            endDateTitle: 'End date range'
        },
    }),
    beforeMount() {
        this.handleChartWidth(this.$vuetify.breakpoint.name, 'funnelChartOptions')
    },
    mounted(){
        this.handleChartWidth(this.$vuetify.breakpoint.name, 'pieChartOptions')
    },
    methods: {
        epochDate(value) {
            return moment(value)
                .utcOffset(0)
                .valueOf()
        },
        filterDates(value) {
            const {startDate, endDate} = this.datePickers

            const filledDateFrame = Object.entries(allDates)

            const start = filledDateFrame.findIndex(([date]) => date === startDate)
            const end = filledDateFrame.findIndex(([date]) => date === endDate)

            const startDateEpoch = this.epochDate(startDate), endDateEpoch = this.epochDate(endDate),
                filteredTransactions = Object.fromEntries(filledDateFrame.slice(start,end))

            Object.keys(value).forEach(element => {
                const checkDate = this.epochDate(element)

                if (checkDate >= startDateEpoch && checkDate <= endDateEpoch) filteredTransactions[element] = value[element]
            })

            return Object.keys(filteredTransactions).length ? filteredTransactions : []
        },
        filterAvgPaymentTimeDates(value, datePicker) {
            const {startDate, endDate} = datePicker ?? this.avgPaymentTimeDatePickers
            const startDateEpoch = this.epochDate(startDate)
            let endDateEpoch = this.epochDate(endDate)

            if (endDate.split('-').length < 3) {
                endDateEpoch = moment(endDateEpoch).add(1, 'month')
            }

            return value.filter((item) => {
                const checkDate = this.epochDate(item[0].split('/').reverse().join('-'))
                return checkDate >= startDateEpoch && checkDate <= endDateEpoch;
            })
        },
        isFilledData(item) {
            return !this.dataLengths.includes(item.length) ? item : []
        },
        handleChartWidth(value, key){
            this[key].width = this[key].breakpoints[value]
        },
        isDataEmpty(item) {
            return item.length === 1
        }
    },
    watch: {
        '$vuetify.breakpoint.name'(value) {
            this.handleChartWidth(value, 'pieChartOptions')
            this.handleChartWidth(value, 'funnelChartOptions')
        },
    }
}
