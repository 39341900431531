<template>
  <v-menu
      v-model="menuController"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="dateController"
          :label="title"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          hide-details
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="dateController"
        :max="maxDate"
        :type="type"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  data: () => ({
    dateController: '',
    menuController: ''
  }),
  props: {
    title: {type: String, default: ''},
    initDate: {type: String},
    maxDate: {type: [String, undefined], default: undefined},
    type: {type: String, default: 'date'}
  },
  mounted() {
    this.dateController = this.initDate
  },
  watch: {
    dateController(value) {
      this.$emit('change-date', value)
    }
  }
}
</script>
