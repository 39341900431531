import {avgPaymentTimeHeaders, dateRangeType, paymentOperators} from '@/utils/macros/variables'

export default {
    methods: {
        prepareAveragePaymentTimeData(data, mode) {
            const operators = Object.values(paymentOperators)

            if (mode === dateRangeType.month) {
                data = Object.entries(
                    data.reduce((acc, [date, data]) => {
                        date = date.split('/').splice(1, 3).join('/') // convert DD/MM/YYYY to MM/YYYY format
                        if (acc[date]) {
                            operators.forEach((operator) => {
                                const {average: oldAverage, amount: oldAmount} = acc[date][operator] || {average: 0, amount: 0}
                                const {average: itemAverage, amount: itemAmount} = data[operator] || {average: 0, amount: 0}

                                if (!acc[date][operator]) acc[date][operator] = {amount: 0}
                                acc[date][operator].amount += itemAmount || 0
                                const {amount: newAmount } = acc[date][operator]
                                
                                acc[date][operator].average = parseFloat(
                                    (((oldAverage * oldAmount) + (itemAverage * itemAmount)) / newAmount).toFixed(3)
                                )
                            })
                        } else acc[date] = this.$removeReference(data)
                        return acc
                    }, {})
                )
            }

            data = data.map(([date, data]) => [
                date,
                ...operators.map((operator) => (data[operator]?.average || 0))
            ])

            return [
                avgPaymentTimeHeaders,
                ...data
            ]
        }
    }
}