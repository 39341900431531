<template>
  <v-row class="mt-4 justify-center">
    <v-col cols="12" class="pb-0">
      <v-row justify="center">
        <v-radio-group v-model="localCharType" @change="changeType" row>
          <v-radio :value="dateRangeType.date" :label="text.date"></v-radio>
          <v-radio :value="dateRangeType.month" :label="text.month"></v-radio>
        </v-radio-group>
      </v-row>
    </v-col>

    <v-spacer/>

    <v-col cols="12" class="py-0 my-0" v-if="!hideDates">
      <v-row justify="center" class="py-0 my-0">
        <v-col cols="5" md="2">
          <date-picker
              @change-date="(e) => changeDateRange('startDate', e)"
              :title="avgPaymentTimeDatePickers.startDateTitle"
              :init-date="avgPaymentTimeDatePickers.startDate"
              :max-date="avgPaymentTimeDatePickers.endDate"
              :type="chartType"
          >
          </date-picker>
        </v-col>

        <v-col cols="5" md="2">
          <date-picker
              @change-date="(e) => changeDateRange('endDate', e)"
              :title="avgPaymentTimeDatePickers.endDateTitle"
              :init-date="avgPaymentTimeDatePickers.endDate"
              :type="chartType"
          >
          </date-picker>
        </v-col>
      </v-row>
    </v-col>

    <v-spacer/>

    <v-col cols="12" md="12" class="py-0 my-0">
      <v-row justify="center" class="py-0 my-0">
        <v-col cols="4" md="1" :key="operator" v-for="operator in paymentOperators">
          <v-checkbox
              v-model="operators"
              :label="operator"
              :value="operator"
              hide-details
              @change="checkboxController(operator)"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-spacer/>

    <v-col cols="12" class="pt-0">
      <chart
          v-if="isFilledData(filteredData).length"
          :tooltip="tooltip"
          :data="filteredData"
          :options="avgPaymentTimeChartOptions"
          :type="columnChart"
      >
      </chart>
    </v-col>
  </v-row>
</template>

<script>
import externalChartsMixin from '@/mixins/components/external-charts'
import DatePicker from "@/components/dashboard/collection-evidence/DatePicker";
import {googleChartTypes, paymentOperators} from '@/utils/macros/variables'
import Chart from "@/components/dashboard/charts/chart";
import {dateRangeType} from "@/utils/macros/variables";

export default {
  /**
   * Chart with information about average payment time
   * Data can be filtered by date or/and payment provider
   *
   * @displayName Average Payment Time Chart
   * @version 1.1.6
   */
  name: 'AveragePaymentTimeChart',
  mixins: [externalChartsMixin],
  components: {
    Chart,
    DatePicker
  },
  data: () => ({
    ...googleChartTypes,
    dateRangeType,
    localCharType: null,
    text: {
      date: 'Date',
      month: 'Month'
    },
    /**
     * Active operators (which columns will be shown)
     */
    operators: [paymentOperators.stripe, paymentOperators.bluemedia, paymentOperators.tpay],
    paymentOperators,
    filteredData: null
  }),
  props: {
    /**
     * Tooltip text describing the purpose of the chart data
     */
    tooltip: {
      type: String
    },
    /**
     * Default date filtering type
     *
     * @values date, month
     */
    chartType: {
      type: String
    },
    /**
     * @see https://github.com/devstark-com/vue-google-charts#read-the-google-charts-docs-first
     */
    data: {
      type: Array,
      default: () => []
    },
    /**
     * Disable filtering by date
     */
    hideDates: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    this.localCharType = this.chartType
    this.filteredData = this.data
  },
  watch: {
    chartType(e) {
      this.localCharType = e
    },
    /**
     * Filter and show only that columns which are selected by user
     */
    operators() {
      /**
       * Original data provided by prop "data"
       *
       * @see https://github.com/devstark-com/vue-google-charts#read-the-google-charts-docs-first
       */
      let data = this.data
      for (let operator of Object.values(this.paymentOperators)) {
        /**
         * If payment operator isn't chosen by user do not show his data
         */
        if (!this.operators.includes(operator)) {
          const colIndex = data[0].map(e => e.toLowerCase()).indexOf(operator)
          data = data.map(row => row.filter((_, idx) => idx !== colIndex))
        }
      }
      this.filteredData = data
    }
  },
  methods: {
    /**
     * Change chart mode (data/month)
     *
     * @private
     * @property {Event} event emitted by v-model
     */
    changeType(e) {
      /**
       * Change chart type event
       */
      this.$emit('changeChartType', e)
    },
    /**
     * Change date range which will be shown in chart
     *
     * @private
     * @property {Event} event emitted by v-model
     */
    changeDateRange(key, e) {
      /**
       * Change date range event
       */
      this.$emit('changeDateRange', key, e)
    },
    /**
     * Require at least one check box to be selected
     * @param operator
     */
    checkboxController(operator) {
      if (this.operators.length === 0) {
        this.operators = [operator]
      }
    }
  }
}
</script>